import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import FormAuricolare from './components/FormAuricolare'; // Assicurati di avere il percorso corretto
import VisualizzaDati from './components/VisualizzaDati'; // Assicurati che il percorso sia corretto

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">

          <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
              <Navbar.Brand href="/"><img src={logo} className="App-logo" alt="logo" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link><Link to="/">Dashboard</Link></Nav.Link>
                  <Nav.Link href="/form"><Link to="/form">Inserimento</Link></Nav.Link>
                  <Nav.Link><Link to="/dati">Dati</Link></Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>



        <Routes>
          <Route path="/" element={<div>Dashboard</div>} />
          <Route path="/form" element={<FormAuricolare />} />
          <Route path="/dati" element={<VisualizzaDati />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;