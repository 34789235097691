import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
const ModificaDati = ({ id, onBack, onUpdate }) => {
    console.log('onUpdate:', onUpdate); // Aggiungi questa riga per verificare se onUpdate è definito

    const [dati, setDati] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [auricolare, setAuricolare] = useState('');
    const [id_interno, setIdInterno] = useState('');
    const [dataNascita, setDataNascita] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            id: dati.id,
            auricolare: auricolare || dati.auricolare, // Usa il valore modificato o quello originale
            id_interno: id_interno || dati.id_interno, // Usa il valore modificato o quello originale
            data_nascita: dataNascita || dati.data_nascita // Usa il valore modificato o quello originale
        };

        try {
            const response = await fetch('https://dev-geca.geca-web.net/gestionale/backend/edit.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Errore nella rete');
            }

            const result = await response.json();
            console.log('Successo:', result);
            // Aggiorna lo stato con i nuovi dati
            setDati((prevDati) => ({
                ...prevDati,
                auricolare: data.auricolare,
                id_interno: data.id_interno,
                data_nascita: data.data_nascita
            }));
            // Dopo una modifica riuscita, chiama onUpdate con i dati aggiornati
            onUpdate({
                id,
                auricolare: auricolare || dati.auricolare,
                id_interno: id_interno || dati.id_interno,
                data_nascita: dataNascita || dati.data_nascita
            });
            // Puoi gestire il risultato qui, ad esempio, resettare il modulo o mostrare un messaggio di successo
        } catch (error) {
            console.error('Errore:', error);

            // Gestisci l'errore qui, ad esempio, mostrando un messaggio di errore all'utente
        }
    };

    // Logica per recuperare e modificare i dati basata sull'ID fornito
    useEffect(() => {
        const fetchDati = async () => {
            try {
                const response = await fetch(`https://dev-geca.geca-web.net/gestionale/backend/getdata.php?id=${id}`);
                if (!response.ok) {
                    throw new Error('Errore nella rete');
                }
                const result = await response.json();
                setDati(result);
                setAuricolare(result.auricolare); // Imposta il valore iniziale
                setIdInterno(result.id_interno); // Imposta il valore iniziale
                setDataNascita(result.data_nascita); // Imposta il valore iniziale
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchDati();
    }, [id]);

    if (loading) {
        return <div>Caricamento...</div>;
    }

    if (error) {
        return <div>Errore: {error.message}</div>;
    }
    // 
    return (
        <div>
            {/* Pulsante per tornare alla lista dei dati */}
            <Button variant="secondary" onClick={onBack}>
                Torna alla lista
            </Button>
            <h2>Modifica Dati per ID: {id}</h2>
            {/* Mostra i dati recuperati qui, ad esempio: */}
            {dati && (
                <Container>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="auricolare">
                                    <Form.Label>Auricolare</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Auricolare"
                                        value={auricolare}
                                        onChange={(e) => setAuricolare(e.target.value)}
                                        required />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="id_interno">
                                    <Form.Label>ID Interno</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="ID Interno"
                                        value={dati.id_interno}
                                        onChange={(e) => setIdInterno(e.target.value)}
                                        required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3" controlId="data_nascita">
                            <Form.Label>Data Nascita</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="datanascita"
                                value={dati.data_nascita}
                                onChange={(e) => setDataNascita(e.target.value)} />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Modifica
                        </Button>
                    </Form>
                </Container>
            )}


        </div>
    );
};

export default ModificaDati;