import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Modal, Button, Stack } from 'react-bootstrap';
import ModificaDati from './ModificaDati';


const VisualizzaDati = () => {
  const [dati, setDati] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedId, setSelectedId] = useState(null); // Stato per gestire l'ID selezionato
  const [showModal, setShowModal] = useState(false); // Stato per gestire la visibilità del modale




  useEffect(() => {
    const fetchDati = async () => {
      try {
        const response = await fetch('https://dev-geca.geca-web.net/gestionale/backend/get_data.php');
        if (!response.ok) {
          throw new Error('Errore nella rete');
        }
        const result = await response.json();
        setDati(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDati();
  }, []);

  const handleShow = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleClose = () => {
    setSelectedId(null);
    setShowModal(false);
  };

  const handleUpdate = (updatedData) => {
    setDati((prevDati) => prevDati.map(item => item.id === updatedData.id ? updatedData : item));
  };

  if (loading) {
    return <div>Caricamento...</div>;
  }

  if (error) {
    return <div>Errore: {error.message}</div>;
  }
  /*if (selectedId) {
    return <ModificaDati id={selectedId} onBack={handleBackToList} />; // Passa la funzione per tornare indietro
  }*/

  return (
    <div>
      <h2>Dati Inseriti</h2>
      <ListGroup>
        {dati.map((item, index) => (
          <ListGroup.Item key={index}>
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">Auricolare: {item.auricolare}</div>
              <div className="p-2">ID Interno:{item.id_interno} </div>
              <div className="p-2">Third item</div>
            </Stack>
            <Button variant="primary" onClick={() => handleShow(item.id)}>Edit
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica Dati</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedId && (
            <ModificaDati
              id={selectedId}
              onBack={handleClose}
              onUpdate={handleUpdate} // Passa la funzione di aggiornamento
            />
          )}
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default VisualizzaDati;