import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const FormAuricolare = () => {
  const [auricolare, setAuricolare] = useState('');
  const [idInterno, setIdInterno] = useState('');
  const [dataNascita, setdataNascita] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      auricolare,
      idInterno,
    };

    try {
      const response = await fetch('https://dev-geca.geca-web.net/gestionale/backend/insert.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Errore nella rete');
      }

      const result = await response.json();
      console.log('Successo:', result);
      // Puoi gestire il risultato qui, ad esempio, resettare il modulo o mostrare un messaggio di successo
    } catch (error) {
      console.error('Errore:', error);
      // Gestisci l'errore qui, ad esempio, mostrando un messaggio di errore all'utente
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="auricolare">
              <Form.Label>Auricolare</Form.Label>
              <Form.Control type="text" placeholder="Auricolare" onChange={(e) => setAuricolare(e.target.value)} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="idInterno">
              <Form.Label>ID Interno</Form.Label>
              <Form.Control type="text" placeholder="ID Interno" onChange={(e) => setIdInterno(e.target.value)} required />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="idInterno">
          <Form.Label>Data Nascita</Form.Label>
          <Form.Control type="date" placeholder="datanascita" onChange={(e) => setdataNascita(e.target.value)} required />
        </Form.Group>

        <Button variant="primary" type="submit">
          Invia
        </Button>
      </Form>
    </Container>
  );
};

export default FormAuricolare;